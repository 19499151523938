<template>
  <div>
    <b-row class="no-gutters">
      <b-col xl="6" class="text-center text-sm-left mb-3 mb-sm-0">
        <h1 class="font-weight-bold header-main text-uppercase mb-3">
          จัดการหมวดหมู่สินค้ากลุ่ม
        </h1>
      </b-col>
      <b-col xl="6" class="text-right d-flex justify-content-end">
        <b-dropdown class="ml-1 lang-dd border-0 mb-2" right>
          <b-dropdown-item-button @click="importProduct">
            นำเข้าข้อมูล (.xlsx)
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="downloadTemplate">
            ดาวน์โหลดไฟล์ต้นฉบับ
          </b-dropdown-item-button>
        </b-dropdown>
      </b-col>
    </b-row>
    <template v-if="$isLoading">
      <div class="bg-white p-3">
        <CategoryHierarchy
          ref="categoryHierarchy"
          :dataList="selectCategory"
          :catagories="catagories"
          @onDataChange="onDataChangeCategory"
          :hasCreateBtn="false"
          isDisplayAll
        />
      </div>
      <div class="bg-white px-3 pt-3 mt-2">
        <b-row>
          <b-col md="6"></b-col>
          <b-col md="6">
            <div class="d-flex justify-content-end">
              <b-button
                class="btn-main"
                @click="openModalProduct()"
                :disabled="selectCategory.length == 0"
                >เพิ่มสินค้า</b-button
              >
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-table
              class="table-list mb-0"
              striped
              responsive
              hover
              :items="productGroups"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
              :current-page="filter.PageNo"
              :per-page="filter.PerPage"
            >
              <template v-slot:cell(imageUrl)="data">
                <div class="position-relative">
                  <div
                    class="square-box b-contain"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.imageUrl + ')',
                    }"
                  ></div>
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.name }}</p>
              </template>
              <template v-slot:cell(productTypeId)="data">
                <p class="mb-0 nobreak two-lines">
                  {{ format(data.item.productTypeId) }}
                </p>
              </template>
              <template v-slot:cell(sku)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.sku }}</p>
              </template>
              <template v-slot:cell(price)="data">
                <p class="m-0">฿ {{ data.item.price | numeral("0,0.00") }}</p>
              </template>
              <template v-slot:cell(stock)="data">
                <p class="m-0">{{ data.item.stock | numeral("0,0") }}</p>
              </template>
              <template v-slot:cell(display)="data">
                <div v-if="data.item.display == true" class="text-success">
                  <font-awesome-icon icon="check" title="display" />
                </div>
                <div v-else class="text-danger">
                  <font-awesome-icon icon="times" title="not display" />
                </div>
              </template>
              <template v-slot:cell(enabled)="data">
                <div class="w-100 toggle">
                  <div v-if="data.item.enabled == true" class="text-success">
                    ใช้งาน
                  </div>
                  <div v-else class="text-danger">ไม่ใช้งาน</div>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <div class="bg-white pb-3">
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-3 px-3">
          <b-col md="6"></b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="submit"
              :disabled="isDisable || formSubmit.productId.length == 0"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
          </b-col>
        </b-row>
      </div>
    </template>
    <ModalSku
      ref="ModalSku"
      @handleData="handleData"
      :productSelected="formSubmit.productId"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalImportFile
      ref="modalImportFile"
      path="product/ImportBundleExcel"
      colorBtnConfirm="primary"
      @success="importSuccess"
    />
  </div>
</template>

<script>
import CategoryHierarchy from "@/components/categoryHierarchy/CategoryHierarchyV2";
import ModalSku from "./componants/ModalSku";
import ModalImportFile from "@/components/modal/ModalImportFile";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import axios from "axios";
import * as moment from "moment/moment";

export default {
  components: {
    CategoryHierarchy,
    ModalSku,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    ModalImportFile,
  },
  data() {
    return {
      selectCategory: [],
      catagories: [],
      productGroups: [],
      modalMessage: "",
      isBusy: false,
      isDisable: false,
      fields: [
        { key: "imageUrl", label: "ภาพประกอบ", class: "w-100px text-nowrap" },
        {
          key: "name",
          label: "รายละเอียดสินค้า",
          class: "w-100px text-nowrap",
        },
        {
          key: "productTypeId",
          label: "ประเภทของสินค้า",
          class: "w-100px text-nowrap",
        },
        { key: "sku", label: "SKU", class: "w-100px text-nowrap" },
        { key: "price", label: "ราคา", class: "w-100px text-nowrap" },
        { key: "stock", label: "สต๊อก", class: "w-100px text-nowrap" },
        { key: "display", label: "แสดงผล", class: "w-100px text-nowrap" },
        { key: "enabled", label: "สถานะ", class: "w-100px text-nowrap" },
      ],
      form: null,
      formSubmit: {
        selectCategory: [],
        productId: [],
      },
      filter: {
        PageNo: 1,
        PerPage: 10,
        Search: "",
        Status: [],
        CategoryId: [],
        SortByOrderOrId: 2,
        // productType: [4]
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      rows: 0,
    };
  },
  created: async function () {
    await this.getDatas();
  },
  methods: {
    getDatas: async function () {
      this.$isLoading = false;
      let categories = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/category/categoryHierarchy`,
        null,
        this.$headers,
        null
      );
      if (categories.result == 1) {
        this.catagories = categories.detail;
        this.$isLoading = true;
      }
    },
    getDataCategory: async function (categoryId) {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/category/${categoryId}`,
        null,
        this.$headers,
        null
      );
      if (data.result == 1) {
        this.form = { ...data.detail };
        if (categoryId == 0) this.form.category.sortOrder = 1;
      }
    },
    getProductList: async function () {
      if (this.selectCategory.length == 0) return;

      this.rows = 0;
      this.isBusy = true;
      let filter = { ...this.filter };
      filter.CategoryId = this.selectCategory;
      filter.PageNo = 1;
      filter.PerPage = -1;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/product/List`,
        null,
        this.$headers,
        filter
      );

      if (resData.result == 1) {
        this.productGroups = resData.detail.dataList;
        this.rows = resData.detail.count;
      }
      this.isBusy = false;
    },
    onDataChangeCategory: async function (list, item) {
      this.dataCategoryHierarchy = { ...list };
      this.selectCategory = [...list.categoryIdList];
      this.getDataCategory(this.dataCategoryHierarchy.selectId || 0);
      this.productGroups = [];
      this.formSubmit.productId = [];
      await this.getProductList();
    },
    handleData(data) {
      if (this.productGroups.length > 0) {
        for (let index = 0; index < data.length; index++) {
          let find = this.productGroups.findIndex((e) => {
            return e.id === data[index].id;
          });
          if (find === -1) this.productGroups.push(data[index]);
        }
      } else this.productGroups = data;

      if (this.productGroups.length > 0) {
        for (const p of this.productGroups) {
          if (!this.formSubmit.productId.includes(p.id))
            this.formSubmit.productId.push(p.id);
        }
      }
      this.rows = this.productGroups.length;
    },
    deleteData(id) {
      for (let index = 0; index < this.productGroups.length; index++) {
        if (this.productGroups[index].id === id) {
          this.productGroups.splice(index, 1);
          this.formSubmit.productId.splice(index, 1);
        }
      }
    },
    openModalProduct() {
      this.$refs.ModalSku.show();
      this.$refs.ModalSku.selected = [];
    },
    submit: async function () {
      this.formSubmit.selectCategory = this.selectCategory;
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/product/UpdateBundleCategory`,
        null,
        this.$headers,
        this.formSubmit
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getProductList();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    format(data) {
      let text = "";
      switch (data) {
        case 1:
          return (text = "สินค้าทั่วไป");
        case 2:
          return (text = "สินค้ามีตัวเลือก");
        case 4:
          return (text = "สินค้ากลุ่ม");
      }
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
    },
    pagination(Page) {
      this.filter.PageNo = Page;
    },
    importProduct: async function () {
      this.$refs.modalImportFile.show();
    },
    downloadTemplate: async function () {
      this.$refs.modalLoading.show();
      axios({
        url: `${this.$baseUrl}/api/product/ExportBundleExcel`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
      })
        .then((response) => {
          this.$refs.modalLoading.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Category-Productgroup-` + dateExcel + `.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    importSuccess: async function () {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
::v-deep .dropdown-menu-right {
  padding: 0rem !important;
}
::v-deep .lang-dd ul {
  width: 100px;
}
</style>
